import { Stack } from "@mui/material";
import React from "react";
import styled from "styled-components";

const TitleText = styled.p`
  color: #101010;
  font-size: 25px;
  font-fami;y: EB Garamond;
  font-weight: bold;
  margin: 0px;
  text-align: justify;
  font-weight: 900;
  border-bottom: 1px solid rgb(185 177 177);
  margin-bottom: 15px;
  padding-bottom: 15px;
`;
const DescriptionText = styled.p`
  color: #323232;
  font-weight: bold;
  font-family: EB Garamond;
  font-size: 20px;
  margin: 0px;
  text-align: justify;
`;
export default function FaqCard({ question, answer, index }) {
  return (
    <div style={{ marginBottom: "25px" }}>
      <Stack direction="row" spacing={2}>
        <TitleText style={{ border: "none" }}>{index + 1}</TitleText>
        <Stack>
          <TitleText>{question}</TitleText>
          <DescriptionText dangerouslySetInnerHTML={{ __html: answer }} />
        </Stack>
      </Stack>
    </div>
  );
}
