import React from "react";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { Stack, Button, Container } from "@mui/material";
import { withRouter } from "react-router";
import Header from "../../components/Header";
import Herobackground from "../../assets/images/HeroBg.png";
import whiteBg from "../../assets/images/whiteBg.png";
import whatProgramEntailImg from "../../assets/images/Programentail.jpg";
import bulletLogo from "../../assets/images/bullet.png";
import borderImageLogo from "../../assets/images/borderImage.png";
import VerticalBorder from "../../assets/images/VerticalBorder.png";
import circleIcon from "../../assets/images/circle.png";
import redBg from "../../assets/images/redBg.png";
import border from "../../assets/images/borderLine.png";
// import durationImg from "../../assets/images/durationImg.png";

const HeroContainer = styled.div`
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  background-image: url(${Herobackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;
const HidePhone = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const DesignedForMobile = styled.div`
  border-bottom: 5px solid transparent;
  border-image: url(${borderImageLogo}) 30 stretch;
  border-image-width: 0px 20px 5px 1px;
  color: #323232;
  font-weight: bold;
  font-family: "EB Garamond";
  text-align: center;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledUl = styled.ul`
  list-style-image: url(${bulletLogo});
`;
const StyledLi = styled.li`
  font-size: 35px;
  letter-spacing: 2px;
  color: #ffffff;
  font-weight: bold;
  font-family: "EB Garamond";
  &:before {
    background-image: url(${bulletLogo});
  }
`;
const WhiteContainer = styled.div`
  padding: 60px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  background-image: url(${whiteBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 768px) {
    padding: 60px 20px !important;
  }
  @media (max-width: 1444px) {
    padding: 60px 40px;
    width: auto;
  }
`;
const ProGramEntailConatiner = styled.div`
  padding: 60px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(126, 29, 16);
  min-height: 800px;
  // background: linear-gradient(rgb(0, 0, 0, 0.7), rgb(0, 0, 0, 0.8)),
  //   url(${whatProgramEntailImg});
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  @media (max-width: 768px) {
    padding: 60px 20px;
  }
  @media (max-width: 1444px) {
    padding: 60px 40px;
    width: auto;
  }
`;
const WarningContainer = styled.div`
  border-radius: 5px;
  background-color: #ffd511;
  padding: 10px;
  font-size: 20px;
  color: #101010;
  font-weight: bold;
  font-family: "EB Garamond";
  text-align: center;
`;
const HideBigScreen = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const SubTitleLiText = styled.p`
  opacity: 0.8;
  font-size: 25px;
  color: #f3f3f3;
  font-weight: bold;
  font-family: "EB Garamond";
`;
const RedContainer = styled.div`
  padding: 70px 0px;
  background-color: #7e1d10;
  width: 100%;
  font-size: 24px;
  color: #f3f3f3;
  font-weight: bold;
  font-family: "EB Garamond";
  text-align: center;
  @media (max-width: 1444px) {
    padding: 70px 40px;
    width: auto;
  }
  @media (max-width: 768px) {
    padding: 70px 20px;
    width: auto;
  }
`;

const HeroTitle = styled.p`
  font-size: 63px;
  color: #ffffff;
  font-weight: bold;
  font-family: "EB Garamond";
  text-align: center;
  margin-bottom: 40px;
  margin-top: 100px;
  @media (max-width: 768px) {
    font-size: 50px;
  }
`;

const SubTitleContainer = styled.div`
  border-radius: 5px;
  background-color: #f9b642;
  padding: 15px 40px;
  font-size: 27px;
  color: #101010;
  font-weight: bold;
  font-family: "EB Garamond";
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-bottom: 30px !important;
`;
const TitleTextForWhatWeOffer = styled.p`
  font-size: 63px;
  color: #101010;
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: bold;
  font-family: "EB Garamond";
  @media (max-width: 768px) {
    font-size: 48px;
  }
`;
const TitleTextForProgramEntail = styled.p`
  font-size: 63px;
  color: #ffffff;
  font-weight: bold;
  font-family: "EB Garamond";
  @media (max-width: 768px) {
    font-size: 48px;
  }
`;
const WhatWeOfferCardConatiner = styled.div`
  border-radius: 20px;
  background-color: #f9f9f9;
  border: 2px solid #cacaca;
  position: relative;
`;
const WhatWeOfferBottomRed = styled.div`
  border-radius: 0px 0px 20px 20px;
  background-color: #7e1d10;
  height: 15px;
  position: absolute;
  bottom: -1px;
  width: 100%;
`;
const WhatWeOfferText = styled.p`
  font-size: 25px;
  color: #101010;
  font-weight: bold;
  margin: 0px;
  text-align: Center;
  font-family: "EB Garamond";
  padding: 20px;
  padding-bottom: 40px;
`;

const StyledButton = styled(Button)`
  border-radius: 20px !important;
  background-color: #ffffff !important;
  color: #7e1d10 !important;
  line-height: 36px !important;
  text-transform: uppercase;
  color: #7e1d10;
  font-weight: 800 !important;
  font-family: EB Garamond !important;
  font-size: 30px !important;
  text-align: center;
  padding: 20px 40px !important;
`;
const CenterImage = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
`;
const InfoContainer = styled.div`
  color: #323232;
  font-weight: bold;
  font-family: "EB Garamond";
  text-align: center;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  position: relative;
`;
function Home(props) {
  return (
    <div>
      <HeroContainer>
        <Header />
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ paddingBottom: "70px" }}
        >
          <HeroTitle>The Vajpayee Professionals Program</HeroTitle>
          <SubTitleContainer>
            <p style={{ margin: "0px" }}>
              An opportunity to help{" "}
              <span style={{ color: "#7e1d10" }}>
                {" "}
                create history for tomorrow's India!
              </span>
            </p>
          </SubTitleContainer>
          <Stack
            spacing={3}
            alignItems={{
              sm: "center",
              xm: "center",
              md: "flex-end",
              lg: "flex-end",
            }}
            justifyContent="center"
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              xl: "row",
            }}
          >
            {/* <StyledButton
              size="large"
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSd5JDpzkBEDV6crQzeFqXt__QMuyT6lxZCARMyA511uOlsUaA/viewform",
                  "_blank"
                )
              }
            >
              APPLY NOW
            </StyledButton> */}
          </Stack>
        </Stack>
      </HeroContainer>
      <RedContainer>
        <Container maxWidth="xl">
          <span>
            Administered by the{" "}
            <span style={{ color: "#ffce0c" }}>
              {" "}
              Policy, Politics and Governance Foundation (PPGF),
            </span>{" "}
            the{" "}
            <span style={{ color: "#ffce0c" }}>
              Vajpayee Professionals Program{" "}
            </span>{" "}
            is a call to action to all experienced professionals with a drive
            for nation-building to be the flag bearers of transformation. It is
            a unique opportunity to collaborate with a{" "}
            <span style={{ color: "#ffce0c" }}>premier national party </span>{" "}
            till the 2024 General Election to bring about desired policy and
            political outcomes.
            <br />
            <br />A{" "}
            <span style={{ color: "#ffce0c" }}>
              Vajpayee Professional{" "}
            </span>{" "}
            will explore the frontiers of political consulting and delve deep
            into the realm of strategy formulation, political campaigning and
            election management.
          </span>
        </Container>
      </RedContainer>
      <div style={{ background: "#ffffff" }}>
        <WhiteContainer>
          <Container maxWidth="xl">
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{ marginBottom: "40px" }}
            >
              <TitleTextForWhatWeOffer style={{ marginBottom: "0px" }}>
                What do we offer?
              </TitleTextForWhatWeOffer>
              <img
                src={border}
                alt="border"
                width="auto"
                style={{ maxWidth: "500px", width: "100%" }}
              />
            </Stack>
            <Grid
              container
              spacing={4}
              alignItems="stretch"
              justifyContent="center"
              sx={{ marginBottom: "40px" }}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div style={{ position: "relative", height: "100%" }}>
                  <WhatWeOfferCardConatiner style={{ height: "100%" }}>
                    <WhatWeOfferText>
                      A chance to work in leading roles across a political
                      consultancy with a leading national party for the{" "}
                      <span style={{ color: "#7e1d10" }}>
                        upcoming general elections
                      </span>{" "}
                      thereby contributing to nation building.
                    </WhatWeOfferText>
                    <WhatWeOfferBottomRed />
                  </WhatWeOfferCardConatiner>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div style={{ position: "relative" }}>
                  <WhatWeOfferCardConatiner style={{ height: "100%" }}>
                    <WhatWeOfferText>
                      An opportunity to take up potential full-time roles in
                      leading political consultancy firms as well as the
                      personal offices of the premier{" "}
                      <span style={{ color: "#7e1d10" }}>
                        elected representative
                      </span>{" "}
                      in the country at the end of the program.
                    </WhatWeOfferText>
                  </WhatWeOfferCardConatiner>
                  <WhatWeOfferBottomRed />
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={4}
              alignItems="stretch"
              justifyContent="center"
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div style={{ position: "relative", height: "100%" }}>
                  <WhatWeOfferCardConatiner>
                    <WhatWeOfferText>
                      The experience of being at the heart of election
                      management, campaigning and on-ground strategy formulation
                      for a{" "}
                      <span style={{ color: "#7e1d10" }}>
                        leading national party.
                      </span>
                    </WhatWeOfferText>
                  </WhatWeOfferCardConatiner>
                  <WhatWeOfferBottomRed />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div style={{ position: "relative", height: "100%" }}>
                  <WhatWeOfferCardConatiner>
                    <WhatWeOfferText>
                      <span style={{ color: "#7e1d10" }}>
                        Competitive remuneration
                      </span>{" "}
                      tailored to experienced professionals from premier
                      institutions and leading organisations with years of
                      experience.
                    </WhatWeOfferText>
                  </WhatWeOfferCardConatiner>
                  <WhatWeOfferBottomRed />
                </div>
              </Grid>
            </Grid>
          </Container>
        </WhiteContainer>
        <ProGramEntailConatiner>
          <Container maxWidth="xl">
            <div style={{ maxWidth: "900px" }}>
              <Stack
                justifyContent="center"
                alignItems="flex-start"
                spacing={3}
                sx={{ marginBottom: "40px" }}
              >
                <TitleTextForProgramEntail
                  style={{ margin: "0px"}}
                >
                  What does the Program entail?
                </TitleTextForProgramEntail>
                <img
                  src={border}
                  alt="border"
                  width="auto"
                  style={{ maxWidth: "500px", width: "100%" }}
                />
              </Stack>
              <StyledUl>
                <StyledLi>
                  Induction into live projects.
                  <br />
                  <SubTitleLiText>
                    The Professionals will be directly inducted into a live
                    project to acclimatise them to the organisation and to
                    provide them an intensive understanding of the space.
                  </SubTitleLiText>
                </StyledLi>
                <StyledLi>
                  Placement within political consulting.
                  <br />
                  <SubTitleLiText>
                    Post the live project, the Professionals will be placed
                    within a political consulting organisation in diverse roles,
                    which could be managerial or client-facing in nature.
                  </SubTitleLiText>
                </StyledLi>
                <StyledLi>
                  Working on varied verticals.
                  <br />
                  <SubTitleLiText>
                    The verticals that the Professionals will be working with
                    can encompass areas such as, election campaign management,
                    political strategy creation, policy formulation etc.
                  </SubTitleLiText>
                </StyledLi>
              </StyledUl>
            </div>
          </Container>
        </ProGramEntailConatiner>
        <div style={{ background: "#ffffff" }}>
          <WhiteContainer>
            <Container maxWidth="xl">
              <Stack justifyContent="center" alignItems="center" spacing={3}>
                <TitleTextForWhatWeOffer style={{ textAlign: "center" }}>
                  Who is the Program designed for?
                </TitleTextForWhatWeOffer>
                <img
                  src={border}
                  alt="border"
                  width="auto"
                  style={{ maxWidth: "500px", width: "100%" }}
                />
                <TitleTextForWhatWeOffer
                  style={{ textAlign: "center", fontSize: "25px" }}
                >
                  This program is designed for working professionals capable of
                  handling dynamic situations who are passionate about making a
                  meaningful contribution to the political arena of India.
                </TitleTextForWhatWeOffer>
                <WarningContainer>
                  If interested, join us for a unique opportunity to-
                </WarningContainer>
              </Stack>
              <HideBigScreen>
                <DesignedForMobile>
                  <p>
                    Develop and implement{" "}
                    <span style={{ color: "#911000" }}>
                      {" "}
                      winning political strategies{" "}
                    </span>
                    for your clients to support their campaign.
                  </p>
                </DesignedForMobile>
                <DesignedForMobile>
                  <p>
                    Craft cutting-edge{" "}
                    <span style={{ color: "#911000" }}>
                      communication strategies{" "}
                    </span>{" "}
                    that captivate and inspire the biggest shareholders of
                    Indian democracy – the voters.
                  </p>
                </DesignedForMobile>
                <DesignedForMobile>
                  <p>
                    Work on{" "}
                    <span style={{ color: "#911000" }}>
                      {" "}
                      policy and manifesto creation{" "}
                    </span>{" "}
                    thereby leaving a lasting imprint on India's future.
                  </p>
                </DesignedForMobile>
                <DesignedForMobile>
                  <p>
                    Innovate and execute impactful political campaigns such as{" "}
                    <span style={{ color: "#911000" }}>
                      ‘Chai pe Charcha.’{" "}
                    </span>
                    Your idea can be the next big political movement!
                  </p>
                </DesignedForMobile>
              </HideBigScreen>
              <HidePhone>
                <div style={{ position: "relative", marginTop: "42px" }}>
                  <div
                    style={{
                      display: "flex",
                      height: "300px",
                    }}
                  >
                    <InfoContainer>
                      <p style={{ maxWidth: "70%" }}>
                        Develop and implement{" "}
                        <span style={{ color: "#911000" }}>
                          {" "}
                          winning political strategies{" "}
                        </span>
                        for your clients to support their campaign.
                      </p>
                      <img
                        src={borderImageLogo}
                        width="100%"
                        alt="border"
                        style={{
                          position: "absolute",
                          bottom: "0",
                          width: "80%",
                        }}
                      />
                    </InfoContainer>
                    <InfoContainer>
                      <p style={{ maxWidth: "70%" }}>
                        Craft cutting-edge{" "}
                        <span style={{ color: "#911000" }}>
                          communication strategies{" "}
                        </span>{" "}
                        that captivate and inspire the biggest shareholders of
                        Indian democracy – the voters.
                      </p>
                      <img
                        src={VerticalBorder}
                        alt="border"
                        style={{
                          position: "absolute",
                          left: "0",
                          top: "8px",
                          height: "75%%",
                        }}
                      />
                      <img
                        src={borderImageLogo}
                        width="100%"
                        alt="border"
                        style={{
                          position: "absolute",
                          bottom: "0",
                          width: "80%",
                        }}
                      />
                    </InfoContainer>
                  </div>
                  <CenterImage src={circleIcon} alt="curcle" />
                  <div style={{ display: "flex", height: "300px" }}>
                    <InfoContainer>
                      <p style={{ maxWidth: "70%" }}>
                        Work on{" "}
                        <span style={{ color: "#911000" }}>
                          {" "}
                          policy and manifesto creation{" "}
                        </span>{" "}
                        thereby leaving a lasting imprint on India's future.
                      </p>
                    </InfoContainer>
                    <InfoContainer>
                      <p style={{ maxWidth: "70%" }}>
                        Innovate and execute impactful political campaigns such
                        as{" "}
                        <span style={{ color: "#911000" }}>
                          ‘Chai pe Charcha’.{" "}
                        </span>
                        Your idea can be the next big political movement!
                      </p>
                      <img
                        src={VerticalBorder}
                        alt="border"
                        style={{
                          position: "absolute",
                          left: "0",
                          bottom: "0px",
                          height: "75%",
                        }}
                      />
                    </InfoContainer>
                  </div>
                </div>
              </HidePhone>
            </Container>
          </WhiteContainer>
        </div>
      </div>
      <WhiteContainer
        style={{ backgroundImage: `url(${redBg})`, minHeight: "300px" }}
      >
        <Container maxWidth="xl">
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{ marginBottom: "40px" }}
          >
            <TitleTextForWhatWeOffer
              style={{ textAlign: "center", color: "#ffffff" }}
            >
              What is the duration of the Program?
            </TitleTextForWhatWeOffer>
            <img
              src={border}
              alt="border"
              width="auto"
              style={{ maxWidth: "500px", width: "100%" }}
            />
          </Stack>
          <Grid
            container
            spacing={3}
            alignItems="stretch"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={6} lg={12}>
              <Stack
                justifyContent="center"
                alignItems="center"
                spacing={3}
                direction={{
                  lg: "row",
                  md: "row",
                  xs: "column",
                  sm: "column",
                }}
                sx={{ height: "100%" }}
              >
                <div style={{ position: "relative" }}>
                  <WhatWeOfferCardConatiner>
                    <WhatWeOfferText style={{ color: "#323232" }}>
                      The Professionals will be onboarded on a rolling basis
                      from{" "}
                      <span style={{ color: "#911000" }}>
                        October to December 2023.{" "}
                      </span>
                    </WhatWeOfferText>
                  </WhatWeOfferCardConatiner>
                  <WhatWeOfferBottomRed
                    style={{ background: "#000000", bottom: "-4px" }}
                  />
                </div>
                <div style={{ position: "relative" }}>
                  <WhatWeOfferCardConatiner>
                    <WhatWeOfferText style={{ color: "#323232" }}>
                      The Professionals will be actively engaged till{" "}
                      <span style={{ color: "#911000" }}>
                        June 2024.
                      </span>
                    </WhatWeOfferText>
                  </WhatWeOfferCardConatiner>
                  <WhatWeOfferBottomRed
                    style={{ background: "#000000", bottom: "-4px" }}
                  />
                </div>
              </Stack>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={durationImg}
                  alt="duration"
                  width="100%"
                  style={{ maxWidth: "400px" }}
                />
              </div>
            </Grid> */}
          </Grid>
        </Container>
      </WhiteContainer>
    </div>
  );
}
export default withRouter(Home);
