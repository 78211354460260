import React from "react";
import styled from "styled-components";
import { Stack, Container } from "@mui/material";
import Header from "../../components/Header";
import FaqCard from "../../components/FaqCard";

import FaqBanner from "../../assets/images/FaqBg.png";
import faqs from "../../assets/faq.json";

const ApplyButton = styled.button`
  border-radius: 7px;
  background-color: #7e1d10;
  padding: 15px 40px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  font-size: 21px;
  font-family: EB Garamond;
  border: none;
  width: fit-content;
`;
const TitleText = styled.p`
  color: #ffffff;
  font-size: 100px;
  margin: 0px;
  text-align: center;
  font-family: EB Garamond;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const HeroContainer = styled.div`
  background-image: url(/static/media/FaqBg.b328d94….png);
  background-size: cover;
  min-height: 450px;
  background-image: url(${FaqBanner});
  margin-bottom: 50px;
  @media (max-width: 768px) {
    background-size: 100% 450px;
    background-repeat: no-repeat;
  }
`;
const DescriptionText = styled.p`
  color: #323232;
  font-weight: bold;
  font-family: EB Garamond;
  font-size: 20px;
  margin: 0px;
  text-align: left;
`;
const FaqContainer = styled.div``;
export default function FaqPage() {
  return (
    <FaqContainer>
      <HeroContainer>
        <Header />
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: "450px" }}
        >
          <TitleText>FAQ</TitleText>
        </Stack>
      </HeroContainer>
      <Container maxWidth="lg">
        {faqs.map((faq, index) => (
          <FaqCard {...faq} index={index} />
        ))}
        <Stack spacing={2} sx={{ marginBottom: "30px", marginTop: "50px" }}>
          <ApplyButton>More Queries</ApplyButton>
          <DescriptionText>
            In case of further queries, please mail your questions and
            apprehensions to{" "}
            <a
              href="mailto:career@ppgf.in"
              style={{
                textDecoration: "none",
                color: "#0765b5",
                fontStyle: "italic",
              }}
            >
              career@ppgf.in
            </a>
          </DescriptionText>
        </Stack>
      </Container>
    </FaqContainer>
  );
}
