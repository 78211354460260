import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Stack, IconButton } from "@mui/material";

import twitterIcon from "../../assets/images/twitterppgf.png";
import facebookIcon from "../../assets/images/facebookppgf.png";
import instagramIcon from "../../assets/images/instagramppgf.png";
import linkedinIcon from "../../assets/images/linkedinppgf.png";
import logo from "../../assets/images/LOGOName.png";

const CustomHyperLink = styled.a`
  color: #101010;
  font-size: 20px;
  font-weight: bold;
  font-family: EB Garamond;
  text-decoration: none;
`;
const CustomLink = styled(Link)`
  color: #101010;
  font-size: 20px;
  text-decoration: none;
  font-weight: bold;
  font-family: EB Garamond;
`;
const TitleText = styled.p`
  color: #101010;
  font-size: 23px;
  margin: 0px;
  font-weight: 800;
`;
const ApplyButton = styled.button`
  border-radius: 7px;
  background-color: #7e1d10;
  padding: 15px 40px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  font-size: 21px;
  border: none;
  width: 100%;
  cursor:pointer;
`;
export default function Footer() {
  return (
    <div style={{ backgroundColor: "#F0F0F0", paddingTop: "60px" }}>
      <Container
        maxWidth="xl"
        sx={{ background: "#FFFFFF", borderRadius: "10px" }}
      >
        <div style={{ padding: "40px 20px" }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <img src={logo} alt="PPGF" width="190px" />
              <p
                style={{
                  color: "#101010",
                  fontSize: "20px",
                  lineHeight: "30px",
                  fontFamily: "EB Garamond",
                  fontWeight: "bold",
                }}
              >
                Creating opportunities for students and young professionals in
                policy, politics & governance
              </p>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={3}>
              <Stack
                direction="column"
                spacing={3}
                marginLeft={{ lg: "50px", sm: "0px", md: "0px" }}
              >
                <CustomLink to="/">Home</CustomLink>
                <CustomHyperLink
                  href="https://www.ppgf.in/team"
                  target="_blank"
                >
                  Team
                </CustomHyperLink>
                <CustomHyperLink
                  href="https://www.ppgf.in/testimonials"
                  target="_blank"
                >
                  Testimonials
                </CustomHyperLink>
                <CustomHyperLink
                  href="https://www.ppgf.in/contact"
                  target="_blank"
                >
                  Contact us
                </CustomHyperLink>
                {/* <CustomHyperLink href="https://www.ppgf.in/" target="_blank">
                  PPGF (Home)
                </CustomHyperLink> */}
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={3}>
              <Stack direction="column" spacing={3}>
                {/* <CustomHyperLink>Mukherjee Fellowship</CustomHyperLink> */}
                <CustomHyperLink
                  href="https://www.ppgf.in/icpc-internship"
                  target="_blank"
                >
                  ICPC Internship
                </CustomHyperLink>
                <CustomHyperLink
                  href="https://www.ppgf.in/campaign-internship"
                  target="_blank"
                >
                  PPGF Campaign Internship
                </CustomHyperLink>
                <CustomHyperLink
                  href="https://www.ppgf.in/tech-internship"
                  target="_blank"
                >
                  Tech Internship
                </CustomHyperLink>
                <CustomHyperLink
                  href="https://www.ppgf.in/jobs-database"
                  target="_blank"
                >
                  Jobs Database
                </CustomHyperLink>
                <CustomHyperLink
                  href="https://www.ppgf.in/workshop"
                  target="_blank"
                >
                  Workshop
                </CustomHyperLink>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <TitleText>Follow Us On</TitleText>
              <Stack
                direction="row"
                spacing={1}
                sx={{ margintop: "20px", marginBottom: "20px" }}
              >
                <IconButton
                  onClick={() =>
                    window.open("https://twitter.com/PPGFIndia", "_blank")
                  }
                >
                  <img src={twitterIcon} alt="twitter" width="50px" />
                </IconButton>
                <IconButton
                  onClick={() =>
                    window.open("https://www.facebook.com/PPGF.IN", "_blank")
                  }
                >
                  <img src={facebookIcon} alt="twitter" width="50px" />
                </IconButton>
                <IconButton
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/ppgf_india/",
                      "_blank"
                    )
                  }
                >
                  <img src={instagramIcon} alt="twitter" width="50px" />
                </IconButton>
                <IconButton
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/ppgf/",
                      "_blank"
                    )
                  }
                >
                  <img src={linkedinIcon} alt="twitter" width="50px" />
                </IconButton>
              </Stack>
              {/* <ApplyButton
                onClick={() =>
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLSd5JDpzkBEDV6crQzeFqXt__QMuyT6lxZCARMyA511uOlsUaA/viewform",
                    "_blank"
                  )
                }
              >
                Apply Now
              </ApplyButton> */}
            </Grid>
          </Grid>
        </div>
      </Container>
      <Stack
        sx={{ padding: "30px" }}
        alignItems="center"
        justifyContent="center"
      >
        <TitleText style={{ fontSize: "20px" }}>
          © 2023 PPGF. All rights reserved.
        </TitleText>
      </Stack>
    </div>
  );
}
